<template>
  <div class="updateTournament">
    <div class="eventPreHeader">
      <IconButton button-type="fluid" :button-function="()=>{$router.back()}">
      <span class="material-icons">
        chevron_left
      </span>
      </IconButton>
      <p>
        Turnier update
      </p>
    </div>
    <h1>
      Update: {{ tournament.title }}
    </h1>
    <div class="gameDetails">
      <div>
        <label>Game</label>
        <div class="gameInfo">
          <div class="gameTeamSize">
            <div>
              <p>{{ tournament.team_size }}</p>
              <span class="material-icons">person</span>
            </div>
            <p>{{ tournament.game?.full_name }}</p>
          </div>
          <PlainButton v-if="this.tournament.current_registration_phase === 2" button-type="success" :isSmall="true" :isSlim="true" :scaleByChild="true" :buttonFunction="() => nextPhase(3)">Next: Phase 3</PlainButton>
        </div>
      </div>
      <div class="updateForm">
        <div class="inputSwitch">
          <TextInput :input-title="nameInputTitle.description" v-model:input-text="name"/>
          <div class="material-icons switch" @click="changeInputTitle">
            loop
          </div>
        </div>
        <div id="limit">
          <div class="teamLimitToggle">
            <Checkbox :is-active="teamLimitActive" :click-function="toggleTeamLimit"/>
            <p>{{this.tournament.team_size>1?'Team':'Spieler'}} Anmeldungen limitieren</p>
          </div>
          <transition name="team-limit">
            <TextInput v-if="teamLimitActive" input-title="Team Limit" v-model:input-text="teamLimit" :inputPlaceholder="69" :textFieldType="'number'" ></TextInput>
          </transition>
        </div>


        <TextInput input-title="Ingame Name Source" v-model:input-text="ingame_name_source">

        </TextInput>

        <TextInput input-title="Preis" v-model:input-text="prize">

        </TextInput>
        <div>
          <label>Startdatum</label>
          <Datepicker v-model:selected-date="date">

          </Datepicker>
        </div>
        <div>
          <label>Anmeldeschluss</label>
          <Datepicker v-model:selected-date="team_close_date">

          </Datepicker>
        </div>
        <div class="discordToggle">
          <Checkbox :is-active="discordRequired" :click-function="toggleDiscord"/>
          <p>Discord verpflichtend</p>
        </div>
        <div>
          <label>Beschreibung</label>
          <MarkdownEditor v-model:markdown-text="description">

          </MarkdownEditor>
        </div>
        <PlainButton :button-function="()=>updateTournament($route.params.tournament_id,updatedTournament)">
          Update
        </PlainButton>

      </div>
    </div>
    <ErrorPop :error-text="alertMessage" v-if="alertType==='alert-danger'"></ErrorPop>
    <SuccessPop :error-text="alertMessage" v-if="alertType==='alert-success'"></SuccessPop>
  </div>
</template>

<script>
import {adminService} from "@/services";
import TextInput from "@/components/Inputs/TextInput";
import Datepicker from "@/components/Inputs/DatePicker";
import MarkdownEditor from "@/components/Inputs/MarkdownEditor";
import IconButton from "@/components/Buttons/IconButton";
import PlainButton from "@/components/Buttons/PlainButton";
import {mapActions, mapState} from "vuex";
import SuccessPop from "@/components/Popups/SuccessPop";
import ErrorPop from "@/components/Popups/ErrorPop";
import Checkbox from "@/components/Inputs/Checkbox.vue";

let inputTitles=[
  {
    'id': 0,
    'isSuffix': true,
    'description': 'Turnier-Suffix',
    'apiName': 'title_suffix'
  },
  {
    'id': 1,
    'isSuffix': false,
    'description': 'Ganzer Name',
    'apiName': 'title'
  },
]
export default {
  name: "UpdateTournament",
  components: {
    Checkbox,
    PlainButton,
    MarkdownEditor,
    Datepicker,
    TextInput,
    IconButton,
    ErrorPop,
    SuccessPop
  },
  data() {
    return {
      tournament: {},
      nameInputTitle: inputTitles[0],
      name: '',
      ingame_name_source: '',
      prize: '',
      date: new Date(),
      team_close_date: new Date(),
      description: '',
      discordRequired:false,
      teamLimitActive:false,
      teamLimit:0
    }
  },
  created() {
    this.fetchTournament()

  },
  computed: {
    ...mapState('alert', {
      alertMessage: 'message',
      alertType: 'type'
    }),
    updatedTournament(){
      return{
        'title_suffix':  this.nameInputTitle?.isSuffix?(this.name?.length>0?this.name:null):null,
        'title': !this.nameInputTitle.isSuffix?(this.name.length>0?this.name:null):null,
        'description':this.description,
        'datetime':this.date.toJSON(),
        'team_registration_closed':this.team_close_date.toJSON(),
        'prize':this.prize,
        'max_teams':this.teamLimitActive?Number(this.teamLimit):null,
        'discord_required':this.discordRequired
      }
    }
  },
  methods: {
    ...mapActions("alert", ['success', "error"]),
    updateTournament(id, tournament) {
      adminService.updateTournament(id, tournament).then(
          tournament => {
            this.success('Tournament updated: ' + tournament.title);
            this.$router.back()
          },
          error => {
            this.error(Array.isArray(error) ? error[0].msg : error)
          }
      )
    },
    fetchTournament() {
      adminService.fetchTournamentById(this.$route.params.tournament_id).then(tournament => {
        this.tournament = tournament;

        this.ingame_name_source = this.tournament.ingame_name_source
        this.prize = this.tournament.prize
        if (this.tournament.title_suffix !== null && this.tournament.title_suffix !== undefined) {
          this.nameInputTitle = inputTitles[0]
          this.name = this.tournament.title_suffix
        } else {
          this.nameInputTitle = inputTitles[1]
          this.name = this.tournament.title
        }
        this.date = new Date(this.tournament.datetime)
        this.team_close_date = new Date(this.tournament.team_registration_closed)
        this.description = this.tournament.description
        if(this.tournament.max_teams){
          this.teamLimit = this.tournament.max_teams
          this.teamLimitActive = true
        }
        this.discordRequired = this.tournament.discord_required
      });
    },
    changeInputTitle() {

      if (this.nameInputTitle.id === 0) {
        this.name = this.tournament.title
        this.nameInputTitle = inputTitles[1]
      } else {
        this.name = this.tournament.title_suffix
        this.nameInputTitle = inputTitles[0]
      }

    },
    toggleDiscord(){
      this.discordRequired=!this.discordRequired
    },
    toggleTeamLimit(){
      this.teamLimitActive=!this.teamLimitActive
    },
    nextPhase(next) {
      if(next - 1 === this.tournament.current_registration_phase) {
        adminService.moveTournamentToPhase(this.tournament.tournament_id, next).then(res => {
          if(res) {
            this.tournament.current_registration_phase = next;
          }
        });
      }
    },
  }
}
</script>

<style scoped lang="scss">


@import "./src/styles/themes";




h1 {
  font-weight: 600;
  line-height: 1;
  font-size: 4em;
  margin: 16px 0 32px 0;
  @include theme {
    color: theme-get(header-2)
  }
}
.discordToggle{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  gap: 8px;
  p{
    margin:0;
  }
  font-weight: 500;
  @include theme() {
    color: theme-get('header-3')
  }

  font-size: 1.25em;
}
.gameDetails {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  div {
    width: 512px;
  }

  #limit{
    width: 100%;
    .teamLimitToggle {
      display: flex;
      width: 512px;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 16px;
      gap: 8px;
      p{
        margin:0;
      }
      font-weight: 500;
      @include theme() {
        color: theme-get('header-3')
      }
      font-size: 1.25em;
    }
  }



  .team-limit-enter-active, .team-limit-leave-active {
    transition: 0.25s;
  }

  .team-limit-enter-from, .team-limit-leave-to {
    transform: scale(0);
    opacity: 0;
  }

}

.updateTournament {

  label {
    text-align: left;
    align-self: flex-start;
    transition: 0.25s;
    font-weight: 500;
    font-size: 1.5em;
    font-family: 'Poppins', sans-serif;
    @include theme() {
      color: theme-get('text-1')
    }

    &.focused {
      @include theme() {
        color: theme-get('primary-color')
      }
    }
  }

  .inputSwitch {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;

    .switch {
      cursor: pointer;
      padding: 8px;
      width: auto;
      font-size: 32px;
      @include theme() {
        color: theme-get('text-1');
      }
    }
  }

  .eventPreHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 16px;

    p {
      font-size: 1.5em;
      margin: 0;
      @include theme {
        color: theme-get(text-1)
      }
    }
  }

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  padding: 64px;

  .gameInfo {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    @include theme {
      color: theme-get(text-2);
    }
    align-items: center;
    font-size: 1.25em;
  }

  .gameTeamSize {
    display: flex;
    gap: 16px;
    justify-content: flex-start;
    align-items: center;


    div {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 48px;
      border-radius: 26px;
      width: 48px;

      p {
        font-size: 16px;
      }

      @include theme {
        color: theme-get(primary-color);
        background-color: theme-get(primary-background);
      }
    }
  }

  .updateForm {
    align-self: center;
    display: flex;
    justify-content: flex-start;

    flex-direction: column;
    gap: 24px;
  }

}

@media screen and(max-width: 768px) {
  .updateTournament {
    label {
      font-size: 1.125em;
    }
  }


}
</style>
